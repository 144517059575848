// @ts-ignore
import logo from 'assets/cubik-partners.jpg';
import { useLogin } from 'module/Login';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import MicrosoftLogin from 'react-microsoft-login';
import { LOCAL_STORAGE_USER_KEY } from 'module/Login/constants';
import { isDev } from 'utils';
import { LoginContainer, Logo, SubTitle, Title } from './Login.style';

interface LoginProps {
  loginError: string | null;
}

// As microsoftAppKey cannot be undefined
const microsoftAppKey: string = process.env.MICROSOFT_APP_KEY ?? '';

const Login: React.FunctionComponent<LoginProps> = ({ loginError }) => {
  const login = useLogin();
  const [state, setState] = useState({ windowLoaded: false });
  const loginMock = () => {
    const auth = { userPrincipalName: 'josselind@theodo.fr' };
    login(undefined, auth);
  };
  useEffect(() => {
    setState({ windowLoaded: !state.windowLoaded });
  }, []);
  if (typeof window !== 'undefined') {
    const userFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    if (userFromLocalStorage) {
      const auth = { userPrincipalName: userFromLocalStorage };
      login(undefined, auth);
    }
  }

  const { windowLoaded } = state;
  return (
    <LoginContainer>
      <Logo alt="cubiks-partners logo" src={logo} />
      <Title>
        <FormattedMessage id="login.title" />
      </Title>
      <SubTitle>
        <FormattedMessage id="login.subtitle" />
      </SubTitle>
      {windowLoaded &&
        (isDev ? (
          <button onClick={loginMock}>login</button>
        ) : (
          <MicrosoftLogin
            forceRedirectStrategy={true}
            clientId={microsoftAppKey}
            authCallback={login}
            withUserData={true}
          />
        ))}
      {loginError && <div>ERROR: {loginError}</div>}
    </LoginContainer>
  );
};

export default Login;
