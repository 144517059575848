import Button from 'components/Button';
import { Form } from 'formik';
import styled from 'styled-components';
import { colorPalette, getSpacing } from 'stylesheet';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${getSpacing(4)};
  height: 100%;
  background-color: ${colorPalette.cubikBlue};
  color: ${colorPalette.white};
`;

export const LoginForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${getSpacing(70)};
`;

export const LoginButton = styled(Button)`
  align-self: center;
  margin-top: ${getSpacing(8)};
  background-color: ${colorPalette.blueDark};
  color: ${colorPalette.greyLight};
`;

export const Logo = styled.img`
  max-width: 90%;
  width: ${getSpacing(75)};
`;

export const Title = styled.div`
  margin-bottom: ${getSpacing(5)};
  font-size: ${getSpacing(8)};
`;

export const SubTitle = styled.div`
  font-size: ${getSpacing(4)};
  font-weight: lighter;
  margin-bottom: ${getSpacing(30)};
`;
