import { connect } from 'react-redux';

import { getLoginError } from 'module/Login';
import { RootState } from 'redux/types';

import LoginFormContainer from './Login';

const mapStateToProps = (state: RootState) => ({
  loginError: getLoginError(state),
});

export default connect(mapStateToProps)(LoginFormContainer);
